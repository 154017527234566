@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Rubik:wght@300;400;500;600;700&display=swap');

*{
  font-family: 'Rubik', sans-serif;
}

/* Geçiş efektleri için CSS sınıfları */
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

/* Complete.css */
.post-enter {
  opacity: 0;
  transform: scale(0.95);
}

.post-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 500ms, transform 500ms;
}

.post-exit {
  opacity: 1;
}

.post-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}


::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar thumb */
  border-radius: 5px; /* Add rounded corners to the thumb */

}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change the color on hover */
}

@keyframes slideIn {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(50%);
    opacity: 0;
  }
}

.animate-slideIn {
  animation-name: slideIn;
  animation-duration: 0.3s; /* Animasyon süresini isteğe bağlı olarak ayarlayabilirsiniz */
  animation-timing-function: ease-in-out; /* Animasyon geçişini isteğe bağlı olarak ayarlayabilirsiniz */
  animation-fill-mode: both;
}

.animate-slideOut {
  animation-name: slideOut;
  animation-duration: 0.3s; /* Animasyon süresini isteğe bağlı olarak ayarlayabilirsiniz */
  animation-timing-function: ease-in-out; /* Animasyon geçişini isteğe bağlı olarak ayarlayabilirsiniz */
  animation-fill-mode: both;
}
/* YourComponent.css */




.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
