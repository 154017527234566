.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: black;
  }
  

.post {
    @apply flex flex-col items-center justify-center
    w-1/2
    border border-gray-300 rounded-md
    p-4;
}

.postTitle {
    @apply text-2xl font-bold;
}

