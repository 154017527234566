/* AddPost.css */
/* add margın to scroll bars rıght */
.scroll-container {
    max-height: 550px; /* Define a maximum height for the container */
    overflow-y: auto; /* Enable vertical scrollbar */
    scrollbar-width: thin; /* Specify a thin scrollbar for Firefox */
    overflow-x: hidden; /* Disable horizontal scrollbar */

  }
  
  /* Styling for Webkit-based browsers like Chrome and Safari */
  .scroll-container::-webkit-scrollbar {
    width: 10px; /* Set the width of the scrollbar */

  }
  
  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Set the color of the scrollbar thumb */
    border-radius: 5px; /* Add rounded corners to the thumb */

  }
  
  .scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Change the color on hover */

  }

  
  