.modal-enter {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .modal-enter-active {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 300ms , background-color 300ms ,transform 300ms;
  }
  
  .modal-enter-done {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  
  }
  
  .modal-exit {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  .modal-exit-active {
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
    background-color: rgba(0, 0, 0, 0.1);
  }
  